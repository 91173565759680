import { useEffect, ReactChild, useCallback } from "react";

import { IconButton } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";

import FaceIcon from "@material-ui/icons/Face";
import CloseIcon from "@material-ui/icons/Close";
import GetAppIcon from "@material-ui/icons/GetApp";

import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

import { Photo } from "../types";

type ShowProps = {
    open: boolean,
    photoIndex: number,
    photos: Photo[],
    actions: ReactChild,
    onPhotoIndex: (index: number) => void,
    onClose: () => void,
};

export default function Show({ open, photoIndex, photos, actions, onPhotoIndex, onClose }: ShowProps): JSX.Element {

    const handleLeft = useCallback(() => {
        const index = photoIndex - 1;
        if (index < 0) {
            onPhotoIndex(photos.length - 1);
        } else {
            onPhotoIndex(index);
        }
    }, [ onPhotoIndex, photoIndex, photos.length ]);

    const handleRight = useCallback(() => {
        const index = photoIndex + 1;
        if (index >= photos.length) {
            onPhotoIndex(0);
        } else {
            onPhotoIndex(index);
        }
    }, [ onPhotoIndex, photoIndex, photos.length ]);

    useEffect(() => {
        function handleKeyUp(e: KeyboardEvent) {
            switch (e.key) {
                case "Escape":
                    onClose();
                    break;
                case "ArrowLeft":
                    handleLeft();
                    break;
                case "ArrowRight":
                    handleRight();
                    break;

                default:
                    break;
            }
        }

        window.addEventListener("keyup", handleKeyUp);

        return () => {
            window.removeEventListener("keyup", handleKeyUp);
        };
    }, [ handleLeft, handleRight, onClose ]);

    const photo = photos[photoIndex];

    if (!photo) return <></>;

    return <Backdrop className={"show"} open={open}>
        <div className="top">
            <IconButton href={photo.photo.original.url} target="_blank">
                <GetAppIcon />
            </IconButton>
            <IconButton onClick={onClose}>
                <CloseIcon />
            </IconButton>
        </div>
        <div className="center">
            <div className="left">
                <IconButton onClick={handleLeft}>
                    <KeyboardArrowLeftIcon style={{ fontSize: 64 }} />
                </IconButton>
            </div>
            <div className="photo" style={{ backgroundImage: `url("${photo.photo.large.url}")` }} />
            <div className="right">
                <IconButton onClick={handleRight}>
                    <KeyboardArrowRightIcon style={{ fontSize: 64 }} />
                </IconButton>
            </div>
        </div>
        <div className="bottom">
            <div className="text">
                <div className="title">{photo.title}</div>
                <div className="author"><FaceIcon style={{ fontSize: 16 }} /> {photo.author}</div>
            </div>
            <div className="actions">
                {actions}
            </div>
        </div>
    </Backdrop>;
}
