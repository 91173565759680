import moment from "moment";
import "moment/locale/cs";

import "type-safe-form/styles.css";

import { BrowserRouter } from "react-router-dom";

import { Auth0Provider } from "@auth0/auth0-react";
import { StoreProvider } from "./services/store";

import Routes from "./Routes";
import Layout from "./components/Layout";
import ErrorBoundary from "./components/ErrorBoundary";

import { ThemeProvider } from "./components/Theme";

moment.locale("cs");

export default function App(): JSX.Element {
    return <ErrorBoundary>
        <Auth0Provider
            domain="hlasujprofotku.eu.auth0.com"
            clientId="8eL7v98xCxfMV20A1kKfUccj3Ku7LzKU"
            redirectUri={window.location.origin}
            audience="https://hlasujprofotku.cz/api/"
            cacheLocation="localstorage"
        >
            <StoreProvider>
                <BrowserRouter>
                    <ThemeProvider>
                        <Layout>
                            <Routes />
                        </Layout>
                    </ThemeProvider>
                </BrowserRouter>
            </StoreProvider>
        </Auth0Provider>
    </ErrorBoundary>;
}
