import { useAuth0 } from "@auth0/auth0-react";
import { createContext, useEffect, useReducer, useContext, ReactNode, Dispatch } from "react";


import { Store, Actions, User } from "../types";
import { applyAPI } from "./api";

const initialState: Store = {
    user: null,
};

const StoreContext = createContext<{
    dispatch: Dispatch<Actions>,
    state: Store,
}>({
    dispatch: () => undefined,
    state: initialState,
});

function reducer(state: Store, action: Actions): Store {
    switch (action.type) {
        case "LOGIN":
            return { ...state, user: action.user };
        case "LOGOUT":
            return { ...state, user: null };
        default:
            throw new Error(`Unknown action "${JSON.stringify(action)}".`);
    }
}

export function StoreProvider({ children }: { children: ReactNode }): JSX.Element {
    const { isAuthenticated, user: authOUser, getAccessTokenSilently } = useAuth0();
    const [ state, dispatch ] = useReducer(reducer, initialState);

    useEffect(() => {
        (async () => {
            if (isAuthenticated) {
                const accessToken = await getAccessTokenSilently();
                const id = authOUser.sub;


                const user: User = {
                    id,
                    name: authOUser.name,
                    picture: authOUser.picture,
                    role: "user",
                    accessToken,
                };

                const userDB = await applyAPI("getUserInfo", { name: user.name }, user);

                dispatch({ type: "LOGIN", user: { ...user, role: userDB.role } });

            } else {
                dispatch({ type: "LOGOUT" });
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ authOUser, isAuthenticated ]);

    return <StoreContext.Provider value={{ dispatch, state }} >
        {children}
    </StoreContext.Provider>;
}

export function useStore<T>(selector: (state: Store) => T): T {
    const { state } = useContext(StoreContext);
    return selector(state);
}

export function useActions(): (action: Actions) => void {
    const { dispatch } = useContext(StoreContext);
    return (action) => dispatch(action);
}
