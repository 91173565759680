import { useEffect, useState } from "react";
import { API, User } from "../types";

const SERVER_URL = process.env.NODE_ENV === "development" ? "http://localhost:8080" : "";

type APIFrontend = {
    [K in keyof API]: {
        method: API[K]["method"],
        url: API[K]["url"],
    }
};

export const api: APIFrontend = {
    getUserInfo: {
        method: "GET",
        url: "user",
    },
    getCompetitions: {
        method: "GET",
        url: "competition",
    },
    getCompetition: {
        method: "GET",
        url: "competition/:id",
    },
    getPhotos: {
        method: "GET",
        url: "photo",
    },

    getVotes: {
        method: "GET",
        url: "vote",
    },
    addVote: {
        method: "POST",
        url: "vote",
    },
    removeVote: {
        method: "DELETE",
        url: "vote",
    },

    addCompetition: {
        method: "POST",
        url: "competition",
    },
    updateCompetition: {
        method: "PUT",
        url: "competition",
    },
    removeCompetition: {
        method: "DELETE",
        url: "competition",
    },

    addPhoto: {
        method: "POST",
        url: "photo",
    },
    updatePhoto: {
        method: "PUT",
        url: "photo",
    },
    removePhoto: {
        method: "DELETE",
        url: "photo",
    },
};

export async function applyAPI<K extends keyof API>(
    endpoint: K, params: API[K]["params"], user: null | User = null,
): Promise<API[K]["response"]> {
    const { method, url } = api[endpoint];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const resolvedUrl = `/api/${ url.indexOf(":id") > -1 ? url.replace(":id", (params as any).id) : url}`;

    const headers: { [key: string]: string } = { "Content-Type": "application/json" };
    if (user?.accessToken)
        headers["authorization"] = `Bearer ${user.accessToken}`;

    const response = await fetch(
        `${SERVER_URL}${resolvedUrl}${method === "GET" || method === "DELETE" ?
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            `?${Object.keys(params).map(key => `${key}=${decodeURIComponent((params as any)[key])}`).join("&")}` :
            ""
        }`,
        {
            method,
            headers,
            body: method === "POST" || method === "PUT" ? JSON.stringify(params) : undefined,
        },
    );
    const json = await response.json();
    if (response.status === 200) {
        return json;
    } else {
        throw new Error(json.message || "Server error.");
    }
}


// hooks
export function useGet<K extends keyof API>(
    endpoint: K, params: API[K]["params"], user: null | User = null, use = true,
): [data: null | API[K]["response"], reload: () => void] {
    const [ data, setData ] = useState<null | Error | API[K]["response"]>(null);
    const [ reload, setReload ] = useState(true);

    useEffect(() => {
        if (use) {
            (async () => {
                const data = await applyAPI(endpoint, params, user);
                setData(data);
            })();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ endpoint, JSON.stringify(params), reload, user, use ]);

    // TODO: error boundary catch
    if (data instanceof Error) throw data;

    return [ data, () => setReload(r => !r) ];
}

// New idea
/*
type APICool = {
    [K in keyof API]: (params: API[K]["params"], user: User | null) => Promise<API[K]["response"]>
};

export const apiCool: APICool
*/
