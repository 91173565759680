type SizeDefinition = {
    width?: number,
    height?: number,
    maxWidth?: number,
    maxHeight?: number,
};

export type ResizeImageOptions = SizeDefinition & {
    smooth?: boolean,
    type?: "image/jpeg" | "image/png",
    quality?: number,
};

type Size = {
    width: number,
    height: number,
};

const defaultOptions = {
    smooth: true,
    type: "image/png",
};

export async function resizeImage(fileOrUrl: File | string, options: ResizeImageOptions): Promise<string> {
    const opt = { ...defaultOptions, ...options };

    let url = fileOrUrl instanceof File ? await getBase64FromFile(fileOrUrl) : fileOrUrl;
    let image = await getImage(url);

    const parent = document.body;
    const canvas = document.createElement("canvas");
    parent.appendChild(canvas);

    const ctx = canvas.getContext("2d");
    if (!ctx) throw new Error("Your browser doesn't support 2D canvas context.");

    const size = getSize(image, options);

    canvas.width = size.width;
    canvas.height = size.height;

    if (opt.smooth === true) {
        while (size.width < Math.ceil(image.width / 2) || size.height < Math.ceil(image.height / 2)) {
            const halfWidth = Math.ceil(image.width / 2);
            if (size.width < halfWidth) {
                url = await resizeImage(url, { width: halfWidth });
            } else {
                url = await resizeImage(url, { height: Math.ceil(image.height / 2) });
            }
            image = await getImage(url);
        }
    }

    ctx.drawImage(image, 0, 0, size.width, size.height);

    parent.removeChild(canvas);
    return canvas.toDataURL(opt.type, opt.quality);
}

export type RotateImageOptions = {
    // TODO: just for 90 degrees rotation
    /** from -360 ti +360 to right direction */
    degrees: number,
    type?: "image/jpeg" | "image/png",
    quality?: number,
};

export async function rotateImage(fileOrUrl: File | string, options: RotateImageOptions): Promise<string> {
    const opt = { type: "image/png", ...options };

    const url = fileOrUrl instanceof File ? await getBase64FromFile(fileOrUrl) : fileOrUrl;
    const image = await getImage(url);

    const parent = document.body;
    const canvas = document.createElement("canvas");
    parent.appendChild(canvas);

    const ctx = canvas.getContext("2d");
    if (!ctx) throw new Error("Your browser doesn't support 2D canvas context.");

    const angle = opt.degrees * Math.PI / 180;
    const size = getRotatedCoordination(image, angle);

    canvas.width = size.width;
    canvas.height = size.height;

    ctx.translate(size.width / 2, size.height / 2);
    ctx.rotate(angle);
    ctx.drawImage(image, -image.width / 2, -image.height / 2);
    ctx.restore();

    parent.removeChild(canvas);
    return canvas.toDataURL();
}

export async function getBase64FromFile(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(String(reader.result));
        reader.onerror = error => reject(error);
    });
}

// TODO: just for 90 degrees rotation
function getRotatedCoordination({ width, height }: Size, degrees: number): Size {
    if (degrees % 90 < 45) {
        return {
            width: height,
            height: width,
        };
    } else {
        return { width, height };
    }
    /* TODO: improve later
    return {
        width: Math.abs(width * Math.cos(angle)) + Math.abs(height * Math.sin(angle)),
        height: Math.abs(width * Math.sin(angle)) + Math.abs(height * Math.cos(angle)),
    };
    */
}

function getSize(imageSize: Size, { width, height, maxWidth, maxHeight }: SizeDefinition): Size {
    if (width && height) {
        return { width, height };
    }

    const ratio = imageSize.width / imageSize.height;

    if (height) {
        const newWidth = Math.round(ratio * height);
        if (maxWidth && newWidth > maxWidth) {
            return getSize(imageSize, { width: maxWidth });
        } else {
            return { width: newWidth, height };
        }
    }
    if (width) {
        const newHeight = Math.round(width / ratio);
        if (maxHeight && newHeight > maxHeight) {
            return getSize(imageSize, { height: maxHeight });
        } else {
            return { width, height: newHeight };
        }
    }

    if (maxHeight && imageSize.height > maxHeight) {
        return getSize(imageSize, { height: maxHeight, maxWidth });
    }
    if (maxWidth && imageSize.width > maxWidth) {
        return getSize(imageSize, { width: maxWidth, maxHeight });
    }

    return imageSize;
}

async function getImage(url: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.crossOrigin = "anonymous";
        image.onload = () => resolve(image);
        image.onerror = (e) => reject(e);
        image.src = url;
    });
}
