import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActionArea from "@material-ui/core/CardActionArea";

import { Competition } from "../types";
import { useHistory } from "react-router";
import { getReadableDate } from "../services/date";
import { competitionPhoto } from "../constants";

export function CompetitionItem(
    { id, title, description, /* userName, */ photo, dateFrom, dateTo }: Competition,
): JSX.Element {
    const history = useHistory();

    return <Card style={{ width: `${competitionPhoto.width}px` }}>
        <CardActionArea
            onClick={() => history.push(`/competition/${id}`)}
        >
            <CardMedia
                style={{ height: "150px" }}
                image={photo.url}
                title={title}
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    {title}
                </Typography>
                <Typography color="textSecondary">
                    {`${getReadableDate(dateFrom)} - ${getReadableDate(dateTo)}`}
                </Typography>
                {description && <Typography variant="body1" color="textSecondary" component="p">
                    {description}
                </Typography>}
            </CardContent>
        </CardActionArea>
    </Card>;
}
