import { useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import { useGet, applyAPI } from "../services/api";
import { useStore } from "../services/store";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";

import EditIcon from "@material-ui/icons/Edit";
import FaceIcon from "@material-ui/icons/Face";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";

import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";

import Alert from "@material-ui/lab/Alert";

import { getReadableDate, isActualCompetition } from "../services/date";

import Show from "../components/Show";
import { photos as photosConstants } from "../constants";


export default function CompetitionPage(): JSX.Element {
    const history = useHistory();
    const user = useStore(s => s.user);
    const params = useParams<{ competitionId?: string }>();

    const isValidCompetitionId = typeof params.competitionId === "string";
    const competitionId = params.competitionId || "";

    const [ competition ] = useGet("getCompetition", { id: competitionId }, user, isValidCompetitionId);
    const [ photos, reloadPhotos ] = useGet("getPhotos", { competitionId }, user, isValidCompetitionId);
    const [ votes, reloadVotes ] = useGet("getVotes", { competitionId }, user, isValidCompetitionId && user !== null);

    const [ photoIndex, setPhotoIndex ] = useState<null | number>(null);

    const photosSorted = photos === null ? [] : photos.sort((a, b) => b.votes - a.votes);
    const votesSum = photos === null ? 0 : photos.reduce((sum, photo) => sum + photo.votes, 0);

    const isActual = competition === null ? false : isActualCompetition(competition);

    const isLogged = user !== null;
    const isAdmin = user?.role === "admin" || (competition === null ? false : competition.userId === user?.id);
    const isAllowedVote = isActual && isLogged && votes !== null && competition &&
        votes.length < competition.votesPerUser ? true : false;

    async function handleVote(photoId: string) {
        await applyAPI("addVote", { competitionId, photoId }, user);
        reloadPhotos();
        reloadVotes();
    }

    async function handleUnVote(photoId: string) {
        await applyAPI("removeVote", { competitionId, photoId }, user);
        reloadPhotos();
        reloadVotes();
    }

    function isMyVoteHere(photoId: string) {
        if (isLogged && votes) {
            for (const vote of votes) {
                if (vote.photoId === photoId) return true;
            }
        }
        return false;
    }

    return <>
        {competition === null && <CircularProgress />}
        {competition !== null && <>
            <Breadcrumbs aria-label="breadcrumb">
                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                <Link color="inherit" href="/" onClick={(e: any) => {
                    e.preventDefault();
                    history.push("/");
                }}>
                    Přehled soutěží
                </Link>
                <Typography color="textPrimary">{competition.title}</Typography>
            </Breadcrumbs>

            <div className="competition-header">

                <img src={competition.photo.url} alt={competition.title} className="competition-image" />

                <div className="grow-1">
                    <h1>{competition.title}
                        <Chip
                            // variant="outlined"
                            icon={<FaceIcon />}
                            label={competition.userName}
                        />
                    </h1>

                    <Typography color="textSecondary">
                        {`${getReadableDate(competition.dateFrom)} - ${getReadableDate(competition.dateTo)}`}
                    </Typography>

                    <p>{competition.description}</p>

                    {photos && <Typography className="votes" color="textSecondary">
                        Počet všech hlasů: {votesSum}
                    </Typography>}
                    <br />
                    {isLogged && votes && <Typography color="textSecondary">
                        Počet Vašich hlasů: {votes.length} / {competition.votesPerUser}
                    </Typography>}
                </div>
            </div>

            <h2>Galerie fotografií</h2>

            {photos === null && <CircularProgress />}
            {photos !== null && photos.length === 0 && <Alert severity="info">
                Nejsou zde žádné fotografie.
            </Alert>}
            {photos !== null && <div className="photos">
                <GridList cellHeight={250}>
                    {photosSorted.map((photo, i) => <GridListTile
                        key={photo.id}
                        style={{ width: `${photosConstants.thumbnail.width}px` }}
                    >
                        <img
                            src={photo.photo.thumbnail.url}
                            alt={photo.title}
                            onClick={() => setPhotoIndex(i)}
                        />
                        <GridListTileBar
                            title={photo.title}
                            subtitle={photo.author}
                            actionIcon={<>
                                <span className="vote-number">{photo.votes || 0}</span>
                                {isActual && isMyVoteHere(photo.id) && <IconButton
                                    onClick={() => handleUnVote(photo.id)}
                                >
                                    <ThumbDownIcon />
                                </IconButton>}
                                {isAllowedVote && !isMyVoteHere(photo.id) && <IconButton
                                    onClick={() => handleVote(photo.id)}
                                >
                                    <ThumbUpIcon />
                                </IconButton>}
                            </>}
                        />
                    </GridListTile>)}
                </GridList>

                <Show
                    open={photoIndex !== null}
                    photos={photosSorted}
                    photoIndex={photoIndex || 0}
                    actions={<>
                        {photoIndex !== null && photosSorted[photoIndex] && <>
                            <span className="vote-number">{photosSorted[photoIndex].votes || 0}</span>
                            {isActual && isMyVoteHere(photosSorted[photoIndex].id) && <IconButton
                                onClick={() => handleUnVote(photosSorted[photoIndex].id)}
                            >
                                <ThumbDownIcon />
                            </IconButton>}
                            {isAllowedVote && !isMyVoteHere(photosSorted[photoIndex].id) && <IconButton
                                onClick={() => handleVote(photosSorted[photoIndex].id)}
                            >
                                <ThumbUpIcon />
                            </IconButton>}
                        </>}
                    </>}
                    onPhotoIndex={setPhotoIndex}
                    onClose={() => setPhotoIndex(null)}
                />
            </div>}

            {isAdmin && <>
                <Fab className="fab fab-1" color="primary" onClick={() => history.push(`/edit/${competition.id}`)}>
                    <EditIcon />
                </Fab>
            </>}
        </>}
    </>;
}
