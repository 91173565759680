import { Switch, Route } from "react-router-dom";

import HomePage from "./pages/Home";
import EditPage from "./pages/Edit";
import CompetitionPage from "./pages/Competition";
import Page404 from "./pages/404";

export default function Routes(): JSX.Element {

    return <Switch>
        <Route exact path="/" component={HomePage} />

        <Route exact path="/competition/:competitionId" component={CompetitionPage} />

        <Route exact path="/edit/" component={EditPage} />
        <Route exact path="/edit/:competitionId" component={EditPage} />

        <Route path="*" component={Page404} />
    </Switch>;
}
