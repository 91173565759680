import { useState } from "react";

import moment from "moment";
import { Form } from "type-safe-form";
import { useParams, useHistory } from "react-router-dom";

import { useGet, applyAPI } from "../services/api";
import { useStore } from "../services/store";

import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import CircularProgress from "@material-ui/core/CircularProgress";

import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import AddPhotoIcon from "@material-ui/icons/AddAPhoto";
import EditIcon from "@material-ui/icons/Edit";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import RotateRightIcon from "@material-ui/icons/RotateRight";

import Alert from "@material-ui/lab/Alert";

import { Competition, Photo } from "../types";
import { Modal, RemoveButton } from "../components";
import { getBase64FromFile, resizeImage, rotateImage } from "../services/image";

import { competitionPhoto, photos as photosConstants } from "../constants";

const NEW_COMPETITION: Omit<Competition, "userId" | "userName"> = {
    id: "NEW",
    title: "",
    description: "",
    dateFrom: moment().toISOString(),
    dateTo: moment().toISOString(),
    photo: { type: "EASY_DB_FILE", url: "" },
    votesPerUser: 3,
};

const NEW_PHOTO: Omit<Photo, "userId" | "competitionId"> = {
    id: "NEW",
    title: "",
    author: "",
    votes: 0,
    photo: {
        original: { type: "EASY_DB_FILE", url: "" },
        large: { type: "EASY_DB_FILE", url: "" },
        thumbnail: { type: "EASY_DB_FILE", url: "" },
    },
};

export default function EditPage(): JSX.Element {
    const history = useHistory();
    const user = useStore(s => s.user);
    const { competitionId } = useParams<{ competitionId?: string }>();

    const isEdit = typeof competitionId === "string";

    const [ competitionFromServer ] = useGet(
        "getCompetition",
        { id: competitionId || "" },
        user,
        isEdit,
    );


    if (
        user === null || !( // user has to be logged
            user.role === "admin" || // user is admin or
            (user.role === "manager" && !isEdit) || // user is manager and it is new competition
            (user.role === "manager" && competitionFromServer?.userId === user.id)// user is manager of this competition
        )
    ) {
        return <Alert severity="warning">Nemáš práva k editaci.</Alert>;
    } else {

        const competition: null | Omit<Competition, "id"> = competitionId ?
            competitionFromServer :
            { userId: user.id, userName: user.name, ...NEW_COMPETITION };

        return <>
            <Breadcrumbs aria-label="breadcrumb">
                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                <Link color="inherit" href="/" onClick={(e: any) => {
                    e.preventDefault();
                    history.push("/");
                }}>
                    Přehled soutěží
                </Link>
                <Typography color="textPrimary">{competitionId ? "Úprava" : "Vytváření"} soutěže</Typography>
            </Breadcrumbs>
            <h1>{competitionId ? "Úprava" : "Vytváření"} soutěže</h1>

            {competition === null && <CircularProgress />}
            {competition !== null && <Form
                initialValues={{
                    ...competition,
                    dateFrom: new Date(competition.dateFrom),
                    dateTo: new Date(competition.dateTo),
                }}
                onValidate={async values => {
                    if (values.photo.url === "") {
                        return "Fotografie musí být přiložena.";
                    }

                    return true;
                }}
                onSubmit={async (values) => {
                    if (competitionId) {
                        await applyAPI("updateCompetition", {
                            id: competitionFromServer?.id || "",
                            ...values,
                            dateFrom: moment(values.dateFrom).toISOString(),
                            dateTo: moment(values.dateTo).toISOString(),
                        }, user);
                    } else {
                        const id = await applyAPI("addCompetition", {
                            ...values,
                            dateFrom: moment(values.dateFrom).toISOString(),
                            dateTo: moment(values.dateTo).toISOString(),
                        }, user);

                        history.push(`/edit/${id}`);
                    }
                    return "Soutěž uložena";
                }}
            >
                {({ Input, isChanged, isValid, onSubmit, message }) => <>
                    <Input.Title label="Nadpis" required="Povinná položka" />
                    <Input.Description label="Popis" />
                    <Input.Photo.File
                        label="Úvodní fotografie"
                        accept="image/*"
                        renderValue={(value, onChange) => <div className="image-preview">
                            <img src={value.url} />
                            <div className="control">
                                <IconButton onClick={async () => {
                                    const url = await rotateImage(value.url, { ...competitionPhoto, degrees: -90 });
                                    onChange({ type: "EASY_DB_FILE", url });
                                }}>
                                    <RotateLeftIcon />
                                </IconButton>
                                <IconButton onClick={async () => {
                                    const url = await rotateImage(value.url, { ...competitionPhoto, degrees: 90 });
                                    onChange({ type: "EASY_DB_FILE", url });
                                }}>
                                    <RotateRightIcon />
                                </IconButton>
                            </div>
                        </div>}
                        onFiles={async files => {
                            const url = await resizeImage(files[0], competitionPhoto);
                            return { type: "EASY_DB_FILE", url };
                        }}
                    />
                    <Input.DateFrom
                        label="Datum začátku soutěže"
                        formatWrite="D. M. YYYY"
                        formatRead="LL"
                    />
                    <Input.DateTo
                        label="Datum ukončení soutěže"
                        formatWrite="D. M. YYYY"
                        formatRead="LL" />
                    <Input.VotesPerUser label="Kolik může dát uživatel hlasů" min={1} />

                    {message && <Alert severity="info">{message}</Alert>}

                    <Button variant="contained" color="primary" disabled={!isChanged && !isValid} onClick={onSubmit}>
                        Uložit
                    </Button>
                </>}
            </Form>}

            {competitionId && <RemoveButton
                type="fab"
                text="Opravu si přejetu tuto soutěž smazat?
                    Budou zároveň odstraněny všechny přidané fotografie i hlasy."
                onRemove={async () => {
                    await applyAPI("removeCompetition", { id: competitionId }, user);
                    history.push("/");
                }}
            />}


            <h2>Fotografie soutěže</h2>
            {!competitionId && <Alert severity="info">
                Fotografie je možné přidat po uložení základních informací.
            </Alert>}
            {typeof competitionId === "string" && <Photos competitionId={competitionId} />}
        </>;
    }
}

function Photos({ competitionId }: { competitionId: string }): JSX.Element {
    const user = useStore(s => s.user);

    const [ photo, setPhoto ] = useState<null | Photo>(null);

    const [ photos, reloadPhotos ] = useGet("getPhotos", { competitionId }, user);

    return <>
        {photos === null && <CircularProgress />}
        {user && photos !== null && <>
            <Button
                variant="contained"
                color="primary"
                startIcon={<AddPhotoIcon />}
                onClick={() => setPhoto({ ...NEW_PHOTO, userId: user.id, competitionId })}
            >
              Přidat fotografii
            </Button>

            {photos.length === 0 && <Alert severity="info">
                Galerie je zatím prázdná.
            </Alert>}

            <div className="photos-grid">
                <GridList cellHeight={250} cols={4}>
                    {photos.map(photo => <GridListTile key={photo.id}>
                        <img src={photo.photo.thumbnail.url} alt={photo.title} />
                        <GridListTileBar
                            title={photo.title}
                            subtitle={photo.author}
                            actionIcon={<>
                                <IconButton onClick={() => setPhoto(photo)}>
                                    <EditIcon />
                                </IconButton>
                                <RemoveButton
                                    text="Opravu si přejetu tuto fotografii smazat? Budou k ní smazány i všechny hlasy."
                                    onRemove={async () => {
                                        await applyAPI("removePhoto", { id: photo.id }, user);
                                        reloadPhotos();
                                    }}
                                />
                            </>}
                        />
                    </GridListTile>)}
                </GridList>
            </div>
        </>}

        <Modal
            open={photo !== null}
            title="Úprava fotografie"
            onClose={() => setPhoto(null)}
        >
            {photo !== null && <Form
                initialValues={photo}
                onValidate={async values => {
                    if (values.photo.large.url === "") {
                        return "Fotografie musí být přiložena.";
                    }

                    return true;
                }}
                onSubmit={async (values) => {
                    if (values.id === NEW_PHOTO.id) {
                        await applyAPI("addPhoto", values, user);
                    } else {
                        await applyAPI("updatePhoto", values, user);
                    }
                    reloadPhotos();
                    setPhoto(null);
                    return "Fotografie uložena";
                }}
            >
                {({ Input, isValid, onSubmit, message }) => <>
                    <DialogContent>
                        <Input.Title label="Nadpis" required="Povinná položka"/>
                        <Input.Author label="Autor" />
                        <Input.Photo.File
                            label="Fotografie"
                            accept="image/*"
                            renderValue={(value, onChange) => <div className="image-preview">
                                <img src={value.thumbnail.url} />
                                <div className="control">
                                    <IconButton onClick={async () => {
                                        const rotatedOriginal = await rotateImage(value.original.url, {
                                            ...photosConstants.original, degrees: -90,
                                        });
                                        onChange({
                                            thumbnail: {
                                                type: "EASY_DB_FILE",
                                                url: await resizeImage(rotatedOriginal, photosConstants.thumbnail),
                                            },
                                            large: {
                                                type: "EASY_DB_FILE",
                                                url: await resizeImage(rotatedOriginal, photosConstants.large),
                                            },
                                            original: {
                                                type: "EASY_DB_FILE",
                                                url: rotatedOriginal,
                                            },
                                        });
                                    }}>
                                        <RotateLeftIcon />
                                    </IconButton>
                                    <IconButton onClick={async () => {
                                        const rotatedOriginal = await rotateImage(value.original.url, {
                                            ...photosConstants.original, degrees: 90,
                                        });
                                        onChange({
                                            thumbnail: {
                                                type: "EASY_DB_FILE",
                                                url: await resizeImage(rotatedOriginal, photosConstants.thumbnail),
                                            },
                                            large: {
                                                type: "EASY_DB_FILE",
                                                url: await resizeImage(rotatedOriginal, photosConstants.large),
                                            },
                                            original: {
                                                type: "EASY_DB_FILE",
                                                url: rotatedOriginal,
                                            },
                                        });
                                    }}>
                                        <RotateRightIcon />
                                    </IconButton>
                                </div>
                            </div>}
                            onFiles={async files => {
                                const file = files[0];

                                let originalPhoto = await getBase64FromFile(file);
                                if (file.size > 3000000) {
                                    // bigger than 3MB
                                    originalPhoto = await resizeImage(originalPhoto, photosConstants.original);
                                }

                                return {
                                    thumbnail: {
                                        type: "EASY_DB_FILE",
                                        url: await resizeImage(originalPhoto, photosConstants.thumbnail),
                                    },
                                    large: {
                                        type: "EASY_DB_FILE",
                                        url: await resizeImage(originalPhoto, photosConstants.large),
                                    },
                                    original: {
                                        type: "EASY_DB_FILE",
                                        url: originalPhoto,
                                    },
                                };
                            }}
                        />
                        {message && <Alert severity="info">{message}</Alert>}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setPhoto(null)} color="secondary">
                          Zavřít
                        </Button>
                        <Button onClick={onSubmit} color="primary" autoFocus disabled={!isValid}>
                          Uložit
                        </Button>
                    </DialogActions>
                </>}
            </Form>}
        </Modal>
    </>;
}
