/* eslint-disable */
import React from "react";
import Snackbar from "@material-ui/core/Snackbar";

export type ErrorBoundaryProps = {
    error: JSX.Element,
    children: JSX.Element,
}

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, { hasError: boolean, error: null | Error }> {
    static defaultProps = {
        error: <div>😠 Neeee, něco je špatně.</div>,
    }

    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
        };
    }

    componentDidCatch(error: Error, errorInfo: any) {
        const production = process.env.NODE_ENV === "production";

        if (production) {
            console.warn(error);
        } else {
            console.error("ErrorBoundary", error, errorInfo);
        }

        this.setState({ hasError: true, error });
    }

    handleClose = () => {
        this.setState({ error: null });
    }

    render() {
        if (this.state.hasError) {
            return <>
                {this.props.error}
                <Snackbar
                    open={this.state.error !== null}
                    autoHideDuration={6000}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    onClose={this.handleClose}
                >
                    <div className="snackbar-error">
                        {this.state.error?.message}
                    </div>
                </Snackbar>
            </>;
        } else {
            return this.props.children;
        }
    }
}
