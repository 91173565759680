import moment from "moment";
import { Competition, DateTime } from "../types";

export function getReadableDate(date: DateTime): string {
    return moment(date).format("LL");
}

export function isActualCompetition(competition: Competition): boolean {
    const todayStart = moment().startOf("day").toISOString();
    const todayEnd = moment().endOf("day").toISOString();

    return competition.dateFrom <= todayEnd && competition.dateTo >= todayStart;
}

export function isOldCompetition(competition: Competition): boolean {
    const todayStart = moment().startOf("day").toISOString();

    return competition.dateTo < todayStart;
}
