import { useHistory } from "react-router-dom";

import { useGet } from "../services/api";
import { useStore } from "../services/store";

import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";

import { isActualCompetition, isOldCompetition } from "../services/date";
import { CompetitionItem } from "../components/Competition";

export default function HomePage(): JSX.Element {
    const history = useHistory();
    const user = useStore(s => s.user);

    const [ competitions ] = useGet("getCompetitions", {});

    const actualCompetitions = (competitions || [])
        .filter(isActualCompetition)
        .sort((a, b) => b.dateTo.localeCompare(a.dateTo));
    const oldCompetitions = (competitions || [])
        .filter(isOldCompetition)
        .sort((a, b) => b.dateTo.localeCompare(a.dateTo));

    return <div>
        <h1>Hlasuj pro fotku</h1>
        <p>
            Vítejte na stránkách, které se věnují amatérským fotografickým soutěžím pro rodiny s dětmi. <br />
            Pokud jste se nějaké z níže vyhlášených soutěží účastnili, máte zde možnost hlasovat pro nejzajímavější
            fotografie. <br />
            Podmínky soutěže jsou vždy dány pořadatelem akce, v rámci které byla soutěž vypsána. <br />
            Děkujeme za Vaši účast a přejeme mnoho společné radosti.
        </p>

        {competitions === null && <CircularProgress />}
        {Array.isArray(competitions) && actualCompetitions.length === 0 && <Alert
            severity="info"
        >
            Nejsou zde žádné soutěže.
        </Alert>}
        <Grid container spacing={2}>
            {actualCompetitions.map(comp => <Grid key={comp.id} item>
                <CompetitionItem {...comp} />
            </Grid>)}
        </Grid>

        {oldCompetitions.length > 0 && <>
            <h2>Ukončené soutěže</h2>
            <Grid container spacing={2}>
                {oldCompetitions.map(comp => <Grid key={comp.id} item>
                    <CompetitionItem {...comp} />
                </Grid>)}
            </Grid>
        </>}

        {user !== null && (user.role === "manager" || user.role === "admin") && <Fab
            className={"fab"}
            color="primary"
            onClick={() => history.push("/edit")}
        >
            <AddIcon />
        </Fab>}

    </div>;
}
