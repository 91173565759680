import { ReactChild } from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import { useStore } from "../services/store";
import ErrorBoundary from "./ErrorBoundary";

import { version } from "../../package.json";

export default function Layout({ children }: { children: ReactChild }): JSX.Element {
    const { user } = useStore(s => s);
    const { loginWithRedirect, logout } = useAuth0();

    return <div className="layout">
        <AppBar position="fixed">
            <Toolbar variant="dense">
                <Typography variant="h6" color="inherit" className="logo grow-1">
                    <Link to="/">
                        <img alt="Hlasuj pro fotku" src="/logo192.png" className="menu-logo" />
                        Hlasuj pro fotku
                    </Link>
                </Typography>
                {!user && <Button
                    startIcon={<AccountCircleIcon />}
                    onClick={() => loginWithRedirect({ ui_locales: "cs" })}
                >
                    Přihlásit se
                </Button>}
                {user && <>
                    <div className="user-info">
                        <Avatar alt={user.name} src={user.picture} />
                        <Typography color="textSecondary">{user.name}</Typography>
                    </div>
                    <Button onClick={() => logout({ returnTo: window.location.origin })}>Odhlásit se</Button>
                </>}
            </Toolbar>
        </AppBar>
        <section className="main-content grow-1">
            <ErrorBoundary>
                <Container>
                    {children}
                </Container>
            </ErrorBoundary>
        </section>
        <footer>
            <div>© 2021 Filip Paulů</div>
            <div>v{version}</div>
        </footer>
    </div>;
}
