import { ReactChild } from "react";

import { createMuiTheme, ThemeProvider as Provider } from "@material-ui/core/styles";
import primary from "@material-ui/core/colors/blue";

const theme = createMuiTheme({
    palette: {
        type: "dark",
        primary: primary,
        // secondary: red,
        background: {
            // paper: primary[50]
        },
    },
});

export function ThemeProvider({ children }: { children: ReactChild }): JSX.Element {
    return <Provider theme={theme}>
        {children}
    </Provider>;
}
