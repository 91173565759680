import { ReactNode, useState } from "react";


import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

type ModalProps = { open: boolean, onClose: () => void, title?: string, children: ReactNode, actions?: ReactNode };

export function Modal({ open, onClose, title, children, actions }: ModalProps): JSX.Element {
    return <Dialog
        open={open}
        onClose={onClose}
        fullWidth={true}
        maxWidth={"sm"}
    >
        {title && <DialogTitle>{title}</DialogTitle>}
        {typeof children === "string" && <DialogContent>
            <DialogContentText>{children}</DialogContentText>
        </DialogContent>}
        {typeof children !== "string" && children}
        {actions && <DialogActions>
            {actions}
        </DialogActions>}
    </Dialog>;
}

type RemoveProps = {
    type: "button" | "fab",
    text: string,
    onRemove: () => void,
};

RemoveButton.defaultProps = {
    type: "button",
};

export function RemoveButton({ type, text, onRemove }: RemoveProps): JSX.Element {
    const [ open, setOpen ] = useState(false);

    const handleClose = () => setOpen(false);
    return <>
        {type === "button" && <IconButton onClick={() => setOpen(true)}>
            <DeleteIcon color="secondary" />
        </IconButton>}
        {type === "fab" && <Fab className="fab fab-1" color="secondary" onClick={() => setOpen(true)}>
            <DeleteIcon />
        </Fab>}

        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>Smazat</DialogTitle>
            <DialogContent>
                <DialogContentText >
                    {text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Zrušit
                </Button>
                <Button onClick={() => {
                    handleClose();
                    onRemove();
                }} color="secondary">
                    Smazat
                </Button>
            </DialogActions>
        </Dialog>
    </>;
}
