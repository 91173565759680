import { ResizeImageOptions } from "./services/image";

export const competitionPhoto: ResizeImageOptions = {
    width: 350,
    maxHeight: 500,
    type: "image/jpeg",
    quality: 95,
};

export const photos: { [key: string]: ResizeImageOptions } = {
    // Full HD
    large: {
        maxWidth: 1920,
        maxHeight: 1200,
        type: "image/jpeg",
        quality: 80,
    },
    thumbnail: {
        width: 350,
        maxHeight: 500,
        type: "image/jpeg",
        quality: 95,
    },
    // only when the original is too large
    original: {
        maxWidth: 3000,
        maxHeight: 2000,
        type: "image/jpeg",
        quality: 70,
    },
};
